// i18next-extract-mark-ns-start products-valdyn-gear

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							VALDYN can model gear systems at three levels of detail
							– from 2D involute contact, via 3D involute contact
							including tip relief, to full 3D using a finite element
							(FE) model of the tooth and tooth surface modification.
						</p>
						<p>
							The FE model is generated automatically by FEARCE, and
							results can be back-substituted to obtain tooth
							displacement and stress. Spur and helical gears,
							including internal-tooth (epicyclic) gears, can also be
							modelled. When used in conjunction with VALDYN’s 3D
							rolling element bearing and dynamic body (FE) element,
							realistic gearbox models can be built to study
							refinement issues.
						</p>
						<p>
							VALDYN can also import a SABR transmission model, which
							simplifies a model generation. From SABR/GEAR Tooth
							Contact Analysis outputs generating transmission error
							results can be imported as well as similar output from
							Ohio State LDP program. This data can be used providing
							solution speed of simplified models and accuracy of full
							3D FE method.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Optional FE tooth model</li>
							<li>Interaction of gears with complete engine</li>
							<li>
								Automatic model simplification for linear frequency
								domain analysis
							</li>
							<li>Results animation</li>
							<li>Comprehensive plotted output</li>
							<li>SABR model import</li>
							<li>
								SABR/GEAR and LDP transmission analysis results
								import
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-gear", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

